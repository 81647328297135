
.bolt-dropdown-expandable.bolt-dropdown-expandable-text-field.bolt-expandable-textfield{
    flex: 1;
}

.bolt-textfield{
    border-radius: 2px;
    box-shadow: none;
    padding-top: 1px;
    padding-bottom: 2px;
    box-sizing: border-box;
    font-family: Roboto, "Open Sans", sans-serif, Arial;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    position: relative;
    user-select: none;
    border-color: rgb(161, 159, 157);
}

.bolt-textfield:hover {
    border-color: rgb(96, 94, 92);
}

.bolt-dropdown-filter.bolt-textfield {
    border-radius: 2px;
}

.text-ellipsis{
    font-family: Roboto, "Open Sans", sans-serif, Arial;
    font-size: 14px;
}

.focus-treatment{
    background-color: transparent !important;
    border: 2px solid rgb(0, 133, 69) !important;
    outline: 0px !important;
}

.selected{
    background-color: #20c375 !important;
}

.calloutContentDropdown input:focus{
    outline: none;
}

.errorClass > div > div > div{
    border: 1px solid #a4262c;
}

.transparent .searchableDropDownComponent > div > div {
    border: none;
    background: transparent;
}

.transparent .searchableDropDownComponent > div > div input {
    color: #605e5c;
}

.bolt-textfield.focused.focus-treatment{
    box-shadow: none!important;
}

.bolt-focus-visible .bolt-textfield.focused.focus-keyboard-only{
    border-color: rgba(0, 133, 69, 1)!important;
    box-shadow: 0 0 0 3px rgba(0, 133, 69, 0.35)!important;
}

.bolt-focus-visible .bolt-dropdown .bolt-table-row.focused {
    box-shadow: inset 0 0 0 3px rgba(0, 133, 69, 0.35);
}

.bolt-list-row:hover {
    background-color: rgba(31, 142, 53, 0.05);
}

.label{
    font-family: Roboto, "Open Sans", sans-serif, Arial;
    font-size: 14px;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-wrap: break-word;
}

.requiredLabel::after {
    content: " *";
    color: rgb(164, 38, 44);
    padding-right: 12px;
}

.bolt-textfield .suffix{
    margin-right: 0px;
    padding-right: 11px;
}